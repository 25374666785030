import {Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {Platform, ToastController} from '@ionic/angular';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {SplashScreen} from '@capacitor/splash-screen';
import {Router} from '@angular/router';
import {PublicService, SecurityService} from '@cdq/api';
import {Subscription} from 'rxjs';
import {Network} from '@capacitor/network';
import {Deploy} from 'cordova-plugin-ionic/dist/ngx';
import {EnvService} from './services/environment/env.service';
import {GaService} from './services/googleanalytics/ga.service';
import {KeycloakIonicService} from './services/keycloak/keycloak-ionic.service';
import {AlertService} from "./services/alert/alert.service";
import {CDQCAPPlugin} from "./capacitor/cdq/cdq";
declare const gtag: Function;
declare let ga: Function;
declare let dT_;

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy {
	private popupOpenSubscription: Subscription;
	private popupCloseSubscription: Subscription;
	private initializeSubscription: Subscription;
	private statusChangeSubscription: Subscription;
	private revokeChoiceSubscription: Subscription;
	private noCookieLawSubscription: Subscription;

	showSplash = true;
	userRole: 'VENDOR' | 'CLIENT' | 'OFFLINE' = 'OFFLINE';

	offerId = '209';

	routerHidden = true;
	@ViewChild('splash', {static: false})
	splash: ElementRef;

	isLoggedIn = false;
	notifContent = '';
	cookieEnabled = false;
	appOpened = false;

	constructor(
		public platform: Platform,
		private statusBar: StatusBar,
		public router: Router,
		private securityService: SecurityService,
		private toastCtrl: ToastController,
		private keycloak: KeycloakIonicService,
		private deploy: Deploy,
		private envService: EnvService,
		private ga: GaService,
		private publicService: PublicService,
		private alertService: AlertService
	) {
		this.checkConnection().then((status) => {
			try {
				if (status) {
					this.checkApi().then((value) => {
						if (value) {
							this.initializeApp();
						} else {
							console.log('[app] Api connection error, Maintenance')
						}
					})
				} else {
					this.router.navigateByUrl('/not-found');
				}
			} catch (error) {
				console.log('[app] Get network connection error', error);
			}
		})
	}

	checkConnection(): Promise<any> {
		return new Promise((resolve, reject) => {
			Network.getStatus().then((status) => {
				console.log('[app] Network status', status);
				console.log('[app] Status connected ?', status.connected)
				if (status.connected) {
					resolve(status.connected);
				} else {
					resolve(false);
				}
			}).catch(e => {
				reject(e);
			})
		})
	}

	checkApi(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.publicService.checkConnection().forEach(r => {
				console.log('[app] Checking connection with API', r);
				resolve(r);
			}).catch(e => {
				resolve(e);
			})
		})
	}

	async configureAndUpdate() {
		if (this.platform.platforms().includes('mobile')) {
			if (this.envService.automaticUpdateEnabled) {
				await this.deploy.configure({channel: this.envService.ionicDeployChannel, updateMethod: 'none'});
				const update = await this.deploy.checkForUpdate();
				if (update.available) {

          let updateDesc = '(channel: ' + this.envService.ionicDeployChannel + ' ' + update.build + ')';
					if (this.envService.allowDenyUpdate) {
						await this.alertService.ask('Mise à jour', 'Nouvelle Version Disponible.' + updateDesc + '.  Mettre à jour ?', 'Accepter', () => {
							this.runUpdate()
						}, 'Refuser', () => {
							//do nothing
						})
					} else {
						await this.alertService.ask('Mise à jour', 'Nouvelle Version Disponible.' + updateDesc + '.  Mettre à jour ?', 'Accepter',  () => {
							this.runUpdate();
						}, 'Non', () => {
							console.log('[app] User denied update');
						}, 'alert-basic');
						// await this.alertService.simpleInfo('Mise à jour', 'Nouvelle Version Disponible, mettre à jour ?', 'Accepter', () => {
						//   this.runUpdate()
						// })
					}
				} else {
					console.log('[app] No update available');
				}
			}
		}
	}

	async runUpdate() {
		this.alertService.loadWithMessage('Téléchargement en cours ...')
		this.deploy.downloadUpdate((progress) => {
			console.log('app update download', progress);
		}).then((dlok) => {
			this.alertService.loadWithMessage('Veuillez patientez ...')
			if (dlok) {
				this.deploy.extractUpdate((progress) => {
					console.log('[app] app update extract', progress);
				}).then((extractok) => {
					if (extractok) {
						console.log('[app] restarting app following update');
						this.deploy.reloadApp();
					}
				})
			}
		})
	}

	initializeApp(): void {

		console.log(
			'%c\n' +
			'\n' +
			'                @@@@@   @@   @@@    @@@@@@@@\n' +
			'     @@@)      @@@ @)   @@ @@  @@  @        @       (@@@ \n' +
			'       @@@)   @@@      @@@ @)  @@  @        @    (@@@ \n' +
			'             (@@       @@&@@   @@) @        @\n' +
			' @@@@@@@)    (@@    @@ @@@@@  #@@  @   @@   @      (@@@@@@@ \n' +
			'              @@@  @@@  @@@@ @@@   @    @@  @\n' +
			'       @@@)     @@@@    @@@#@@      @@@@@ @@@    (@@@ \n' +
			'     @@@)                                   @&      (@@@ \n' +
			'                                                   ',
			'color: #118080'
		);

		// Init Google Analytics
		this.ga.initGa();




		this.platform.ready().then(() => {

			//load firebaseID
			if (this.platform.platforms().includes('mobile') && !this.platform.platforms().includes('mobileweb')) {
				try {
					CDQCAPPlugin.firebaseInstallationId().then((res) => {
						console.log('[app] firebase InApp Installation Id: ', res.firebaseInstallationId);
					})
				} catch (e) {
					console.log('[app] unable to load firebase installation id')
				}
			}



			Network.addListener('networkStatusChange', (status) => {
				console.log('[app] Network status changed', status);
				if (!status.connected) {
					this.toastCtrl.create({
						position: 'top',
						header: 'Erreur',
						message: 'La communication avec le service est impossible. Vérifiez votre connexion.',
						color: 'danger',
						duration: 3000,
						buttons: [
							{
								side: 'end',
								text: 'OK',
								role: 'cancel',
								handler: () => {
									console.log('[app] Cancel');
								}
							}
						]
					}).then((toast) => {
						toast.present();
					});
				} else {
					console.log('[app] Connection is OK');
				}
			});


			// if (this.platform.platforms().includes('desktop') || this.platform.platforms().includes('mobileweb')) {
			// 	if (!this.ccService.hasAnswered()) {
			// 		this.ccService.open();
			// 	}
			// }

			// this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
			// 	// you can use this.ccService.getConfig() to do stuff...
			// });

			// this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
			// 	// you can use this.ccService.getConfig() to do stuff...
			// });

			// this.initializeSubscription = this.ccService.initialize$.subscribe((event: NgcInitializeEvent) => {
			// 	// you can use this.ccService.getConfig() to do stuff...
			// });

			// this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
			// 	// you can use this.ccService.getConfig() to do stuff...
			// });

			// this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(() => {
			// 	// you can use this.ccService.getConfig() to do stuff...
			// });

			// this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {
			// 	// you can use this.ccService.getConfig() to do stuff...
			// });

			this.keycloak.isLoggedIn().then((connected) => {
				try {
					if (connected) {
						this.isLoggedIn = true;
						this.securityService.apiV1SecurityLoadGet().forEach((r) => {
							this.userRole = r.result.userType;
							if (this.platform.platforms().includes('mobile')) {
								this.statusBar.styleDefault();
								SplashScreen.hide({
									fadeOutDuration: 3000
								});
							} else {
							}
						});
					} else {
						if (this.platform.platforms().includes('mobile')) {
							this.statusBar.styleDefault();
							SplashScreen.hide({
								fadeOutDuration: 3000
							});
						}
					}
				} catch (e) {
					console.log('[app] error while initlizing app', e);
				}
			});

			//google analytics handle
			// this.router.events.subscribe((event) => {
			// 	try {
			// 		if (event instanceof NavigationEnd) {
			// 			ga('set', 'page', event.urlAfterRedirects);
			// 			ga('send', 'pageview');
			// 		}
			// 	} catch (e) {
			// 		console.log('error while setting analytics events', e);
			// 	}
			// });

			console.log('[app] deployment config update ', this.envService.ionicDeployChannel);
			this.configureAndUpdate();
		});
	}

	ngOnDestroy(): void {
		this.popupOpenSubscription.unsubscribe();
		this.popupCloseSubscription.unsubscribe();
		this.initializeSubscription.unsubscribe();
		this.statusChangeSubscription.unsubscribe();
		this.revokeChoiceSubscription.unsubscribe();
		this.noCookieLawSubscription.unsubscribe();
	}
}
