import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { concatMap, distinctUntilChanged } from 'rxjs/operators';



@Injectable({
    providedIn: 'root'
})
export class LoaderProvider {

    private loadingRequestsStream$: Subject<string>;
    private loaderElement: HTMLIonLoadingElement;
    private lazyDismissTimer: any;

    constructor(private loadingCtrl: LoadingController) {
        this.initValues();
    }

    private initValues() {
        this.loaderElement = null;
        this.lazyDismissTimer = null;
        this.loadingRequestsStream$ = new Subject();
        this.loadingRequestsStream$.pipe(
            distinctUntilChanged(),
            concatMap(loader =>{
                if (loader) {
                    return this.createLoader(loader)
                } else {
                    return this.dismissLoader()
                }
            })
        )
            .subscribe(); // we do not worry of unsubscribing here since this service will always be used across the app
    }

    public showLoader(msg?: string) {
        this.loadingRequestsStream$.next(msg);
    }

    public hideLoader() {
        this.loadingRequestsStream$.next();
    }

    private async createLoader(msg?: any):Promise<void> {
        // we check if there is a loader already instantiated:
        if (!this.loaderElement) {
            // if not we create new loader and limit its max duration to 2000ms to prevent blocking loader to hangout indefinitely

            this.loaderElement = await this.loadingCtrl.create({
                cssClass: "load-alert",
                message: msg || "Chargement ...",
                spinner: "crescent",
                // duration: 3000
            });
            // its essential we return a Promise here as this is what concatMap will leverage for serialization
            return this.loaderElement.present();
        } else {
            // if loader element exists already we just return resolved promise:
            return Promise.resolve();
        }
    }

    private async dismissLoader(): Promise<void> {
        // here we check if loader element exists and that there is no timer running already
        if (this.loaderElement && !this.lazyDismissTimer) {
            // we set the timer
            this.lazyDismissTimer = setTimeout(async () => {
                // after 700ms we dismiss our loader element:
                await this.loaderElement.dismiss();
                // nullify our properties right after dismiss promise fulfilled itself:
                this.loaderElement = null;
                clearTimeout(this.lazyDismissTimer);
                this.lazyDismissTimer = null;
                // still remember to return a promise to let concatMap know it can proceed
                return Promise.resolve();
            }, 700)
        } else {
            // if loader element does not exist or if there is already a timer running - there is nothing to dismiss, we just return empty promise
            return Promise.resolve();
        }
    }

}
