import {ChangeDetectorRef, Injectable, NgZone} from '@angular/core';
import {Router} from "@angular/router";
import {Platform} from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public vendorPages: Array<{
    title: string,
    icon: string,
    url: string;
  }>

  public clientPages: Array<{
    title: string,
    icon: string,
    url: string;
  }>

  public offlinePages: Array<{
    title: string,
    icon?: string,
    url?: string,
    action?: any,
  }>

  isA = '/offline/home-web'

  userRole = ''

  countNotifications = 0
  vendorVersion = false

  isHomeVendor = false
  isLoggedIn = false

  private changeDetectorRef: ChangeDetectorRef;


  constructor(
    private router: Router,
    public platform: Platform,
    private ngZone: NgZone,
  ) {
  }

  setChangeDetector(ch: ChangeDetectorRef) {
    this.changeDetectorRef = ch;
  }

  showNavigation() {

    if (this.platform.platforms().includes('desktop')) {
      this.clientPages = [
        {title: 'Recherche', icon: 'search', url: '/clients/client-map'},
        {title: 'Accueil', icon: 'home', url: '/offline/home-web'},
        {title: 'Mon profil', icon: 'person', url: '/clients/client-options/profile'},
        {title: 'Mes favoris', icon: 'heart', url: '/clients/client-options/favoris'},
        {title: 'Mes participations', icon: 'qr-code', url: '/clients/client-options/participations'},
        {title: 'Mes préférences', icon: 'heart-outline', url: '/clients/client-options/preferences'},
        {title: 'Mon portefeuille', icon: 'wallet', url: '/clients/client-options/portefeuille'},
        {title: 'Mes parrainages', icon: 'person-add', url: '/clients/client-options/parrainages'},
        {title: 'Contact', icon: 'call', url: '/clients/client-options/contact'},
        {title: 'Paramètres', icon: 'settings', url: '/clients/client-options/client-parameters'}
      ]
    } else {
      this.clientPages = [
        {title: 'Mes offres', icon: 'grid', url: '/clients/client-map'},
        {title: 'Mon profil', icon: 'person', url: '/clients/client-options/profile'},
        {title: 'Mes favoris', icon: 'heart', url: '/clients/client-options/favoris'},
        {title: 'Mes participations', icon: 'qr-code', url: '/clients/client-options/participations'},
        {title: 'Mon portefeuille', icon: 'wallet', url: '/clients/client-options/portefeuille'},
        {title: 'Mes parrainages', icon: 'person-add', url: '/clients/client-options/parrainages'},
        {title: 'Contact', icon: 'call', url: '/clients/client-options/contact'},
      ]
    }

  }

  refresh(loggedIn: boolean, userRole: string) {
    this.userRole = userRole;
    this.ngZone.run(() => {
      if (loggedIn) {
        this.isLoggedIn = true;
        this.showNavigation();
      } else {
        this.userRole = 'OFFLINE'
        this.offlinePages = [
          // {title: 'Accueil', icon: 'home', url: '/offline/home-web'},
          // {title: 'Contact', icon: 'mail-outline', url: '/clients/client-options/contact'},
          // {title: 'Aide', icon: 'help', url: '/offline/faq'},
        ]
        this.changeDetectorRef.detectChanges();
      }
    })
  }
}
