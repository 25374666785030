import {NgModule} from '@angular/core';
import {NoPreloading, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'offline',
		pathMatch: 'full'
	},
	{
		path: 'offline',
		loadChildren: () => import('./offline/offline.module').then((m) => m.OfflinePageModule),
	},
	{
		path: 'clients',
		loadChildren: () => import('./clients/clients.module').then(m => m.ClientsPageModule),
	},
	{
		path: 'client-offer-detail/:id',
		loadChildren: () => import('./client-research/client-offer-detail/client-offer-detail.module').then(m => m.ClientOfferDetailPageModule)
	},
	{
		path: 'client-vendor-detail/:id',
		loadChildren: () => import('./client-research/client-vendor-detail/client-vendor-detail.module').then(m => m.ClientVendorDetailPageModule)
	},
	{
		path: 'client-vendor-offers',
		loadChildren: () => import('./client-research/client-vendor-offers/client-vendor-offers.module').then(m => m.ClientVendorOffersPageModule)
	},
	{
		path: 'client-research',
		loadChildren: () =>
			import('./client-research/client-offer-detail/client-offer-detail.module').then((m) => m.ClientOfferDetailPageModule)
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: NoPreloading,
			scrollPositionRestoration: 'enabled',
			enableTracing: true,
			relativeLinkResolution: 'legacy'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
