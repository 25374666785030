import {VendorProfilService} from '@cdq/api';
import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {AccountType, CustomerProfilService} from '@cdq/api';
import {AlertService} from '../alert/alert.service';
import * as moment from 'moment';
import {EnvService} from '../environment/env.service';
import {OAuthProviderList} from './interfaces/oauth-provider';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Platform} from '@ionic/angular';
import {appDefaultConnectedRoute} from "../../app.constants";

@Injectable({
  providedIn: 'root'
})
export class KeycloakSocialLoginService {

  private user = new Subject<any>();

  providersApis: OAuthProviderList = {
    'google': {
      url: 'https://people.googleapis.com/v1/people/me',
      params: 'addresses,ageRanges,biographies,birthdays,calendarUrls,clientData,coverPhotos,emailAddresses,events,externalIds,genders,imClients,interests,locales,locations,memberships,metadata,miscKeywords,names,nicknames,occupations,organizations,phoneNumbers,photos,relations,sipAddresses,skills,urls,userDefined'
    },
    'facebook': {
      url: 'https://graph.facebook.com/me',
      params: 'id,name,email,gender,location,address,hometown,locale,picture,birthday,age_range,cover,devices,first_name,last_name,verified,languages,link,sports,timezone'
    },
  }

  constructor(private alertService: AlertService,
              private clientService: CustomerProfilService,
              private vendorService: VendorProfilService,
              private router: Router,
              private envService: EnvService,
              private http: HttpClient,
              public platform: Platform,
              public ngZone: NgZone) {
  }


  sendUser(user: any) {
    console.log('Send User :', user);
    this.user.next(user);
  }

  getUser(): Observable<any> {
    console.log('Get user');
    return this.user.asObservable();
  }

  saveGoogleInfo(userGoogle: any, generalAccount: any, newAccount: any): void {
    if (userGoogle) {
      if (userGoogle.genders && userGoogle.genders.length > 0) {
        if (userGoogle.genders[0].value == 'male') {
          generalAccount.civilite = 1;
        } else if (userGoogle.genders[0].value == 'female') {
          generalAccount.civilite = 2;
        }
      }

      if (userGoogle.addresses) {
        generalAccount.formattedAddress = userGoogle.addresses[0].formattedValue;
        generalAccount.address = userGoogle.addresses[0].streetAddress;
        generalAccount.city = userGoogle.addresses[0].city;
        generalAccount.zipcode = userGoogle.addresses[0].postalCode;
        generalAccount.country = userGoogle.addresses[0].country;
      }
      if (userGoogle.birthdays) {
        if (userGoogle.birthdays[0].date.day.length == 1) {
          userGoogle.birthdays[0].date.day = '0' + userGoogle.birthdays[0].date.day.toString();
        }
        generalAccount.birthdate = `${userGoogle.birthdays[0].date.day.toString()}-${userGoogle.birthdays[0].date.month.toString()}-${userGoogle.birthdays[0].date.year.toString()}`;
      }
      generalAccount.account.firstname = newAccount.firstname;
      generalAccount.account.lastname = newAccount.lastname;
      generalAccount.account.phoneNumber = newAccount.phoneNumber;

      this.saveProfile(newAccount, generalAccount);
    }
  }

  saveFacebookInfo(userFacebook: any, generalAccount: any, newAccount: any): void {
    if (userFacebook) {
      if (userFacebook.gender == 'male') {
        generalAccount.civilite = 1;
      } else if (userFacebook.gender == 'female') {
        generalAccount.civilite = 2;
      }
      generalAccount.birthdate = userFacebook.birthday ? moment(userFacebook.birthday).format("YYYY-MM-DD") : null;
      if (userFacebook.location) {
        generalAccount.formattedAddress = userFacebook.location.name
      }
      generalAccount.account.firstname = newAccount.firstname;
      generalAccount.account.lastname = newAccount.lastname;
      generalAccount.account.phoneNumber = newAccount.phoneNumber;

      this.saveProfile(newAccount, generalAccount);
    }
  }

  socialProviderInfo(provider: any, instance: Keycloak.KeycloakInstance): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.envService.keycloak.url}/realms/${this.envService.keycloak.realm}/broker/${provider}/token`, {
        headers: {
          Authorization: 'Bearer ' + instance.token,
        }
      }).toPromise().then((providerToken: any) => {
        const myHeaders = new Headers();
        myHeaders.append(
          'Authorization',
          'Bearer ' + providerToken['access_token']
        );

        let url = "";
        const providerApi = this.providersApis[provider];
        if (provider == 'google') {
          url = `${providerApi.url}?personFields=${providerApi.params}`;
        } else if (provider == 'facebook') {
          url = `${providerApi.url}?fields=${providerApi.params}&access_token=${providerToken['access_token']}`;
        }

        if(url && !(provider == 'apple')) {
          fetch(url,
            {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow',
            }
          )
            .then((response) => resolve(response.json()))
            .catch((error) => console.log('error', error));
        } else {
          console.log('no api to call for apple...')
          resolve({})
        }

      })
    })
  }

  private saveProfile(newAccount: any, generalAccount: any) {
    if (newAccount.accountType == AccountType.Client) {
      console.log('[keycloak-social-login-service] saving riv account');
      this.clientService.profileSave(generalAccount).forEach(r => {
        console.log('[keycloak-social-login-service] client profile saved');
        this.ngZone.run(() => {
          this.router.navigateByUrl(appDefaultConnectedRoute, {
            replaceUrl: true,
          });
        })
      }).catch(e => {
        console.log('error on profile save', e);
        this.alertService.temp('ERROR API', 2000);
      });
    } else if (newAccount.accountType == AccountType.Vendor) {
      console.log('[keycloak-social-login-service] shoudl display error, because of vendor account on client app !!');
    }
  }
}
