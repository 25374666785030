import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private offer = new Subject<any>()

  private delete = new Subject<any>()

  private participation = new Subject<any>()

  private cancelParticipation = new Subject<any>()

  private register = new Subject<any>()

  private draftOffer = new Subject<any>()

  private updateOffer = new Subject<any>()

  private updateImage = new Subject<any>()

  private researchOffer = new Subject<any>()

  private researchVendor = new Subject<any>()

  private applyFilter = new Subject<any>()

  private deleteNotif = new Subject<any>()

  private notif = new Subject<any>()

  private imgRivUpdate = new Subject<any>()

  private imgComUpdate = new Subject<any>()

  private refreshOfferViewEvent = new Subject<any>()

  private token = new Subject<any>();

  private idpHint = new Subject<any>();

  private refreshHome = new Subject<any>();

  sendRefreshHome() {
    console.log('[shared service] sendRefreshHome Event')
    this.refreshHome.next(null);
  }

  getRefreshHome(): Observable<any> {
    console.log('[shared service] Get refreshHome Event');
    return this.refreshHome.asObservable();
  }

  sendImgComUpdate() {
    console.log('[shared service] Send imgComUpdate Event')
    this.imgComUpdate.next(null);
  }

  getImgComUpdate():Observable<any> {
    console.log('[shared service] Get imgComUpdate Event');
    return this.imgComUpdate.asObservable();
  }

  sendImgRivUpdate() {
    console.log('[shared service] Send imgRivUpdate Event')
    this.imgRivUpdate.next(null);
  }

  getImgRivUpdate():Observable<any> {
    console.log('[shared service] Get imgRivUpdate Event');
    return this.imgRivUpdate.asObservable();
  }

  sendNotifEvent() {
    console.log('[shared service] Send Notif Event')
    this.notif.next(null);
  }

  getNotifEvent():Observable<any> {
    console.log('[shared service] Get notif Event');
    return this.notif.asObservable();
  }

  sendDeleteNotifEvent() {
    console.log('[shared service] Send Delete Notif Event')
    this.deleteNotif.next(null);
  }

  getDeleteNotifEvent():Observable<any> {
    console.log('[shared service] Get delete notif Event');
    return this.deleteNotif.asObservable();
  }

  sendApplyFilterEvent() {
    console.log('[shared service] Send Apply Filter Event')
    this.applyFilter.next(null);
  }

  getApplyFilterEvent():Observable<any> {
    console.log('[shared service] Get Apply Filter Event');
    return this.applyFilter.asObservable();
  }


  sendDraftEvent(object: any) {
    console.log('[shared service] Send Draft Event', object)
    this.draftOffer.next(object);
  }

  getDraftEvent():Observable<any> {
    console.log('[shared service] Get Draft Event');
    return this.draftOffer.asObservable();
  }

  sendResearchVendorEvent(object: any) {
    console.log('[shared service] Send Research Vendor Event', object)
    this.researchVendor.next(object);

  }

  getResearchVendorEvent():Observable<any> {
    console.log('[shared service] Get Research Vendor Event');
    return this.researchVendor.asObservable();
  }

  sendResearchOfferEvent(object: any) {
    console.log('[shared service] Send Research Offer Event', object)
    this.researchOffer.next(object);

  }

  getResearchOfferEvent():Observable<any> {
    console.log('[shared service] Get Research Offer Event');
    return this.researchOffer.asObservable();
  }

  sendUpdateImageEvent() {
    console.log('[shared service] Send Update Image Event')
    this.updateImage.next(null);
  }

  getUpdateImageEvent():Observable<any> {
    console.log('[shared service] Get Update Image Event');
    return this.updateImage.asObservable();
  }

  sendUpdateOfferEvent() {
    console.log('[shared service] Send Update Offer Event')
    this.updateOffer.next(null);
  }

  getUpdateOfferEvent():Observable<any> {
    console.log('Get Update Offer Event');
    return this.updateOffer.asObservable();
  }

  sendRegisterEvent() {
    console.log('[shared service] Send Register Event')
    this.register.next(null);
  }
  getRegisterEvent():Observable<any> {
    console.log('[shared service] Get Register Event');
    return this.register.asObservable();
  }


  sendOfferEvent() {
    console.log('[shared service] Send Offer Click Event')
    this.offer.next(null);
  }
  getOfferEvent():Observable<any> {
    console.log('[shared service] Get Offer Click Event')
    return this.offer.asObservable();
  }

  sendDeleteEvent() {
    console.log('[shared service] Send Delete Click Event')
    this.delete.next(null);
  }

  getDeleteEvent():Observable<any> {
    console.log('[shared service] Get Delete Offer Click Event')
    return this.delete.asObservable();
  }

  sendParticipationEvent() {
    console.log('[shared service] Send Participation Event')
    this.participation.next(null);
  }

  getParticipationEvent():Observable<any> {
    console.log('[shared service] Get Participation Event')
    return this.participation.asObservable();
  }

  sendCancelParticipationEvent() {
    console.log('[shared service] Send Cancel Participation event')
    this.cancelParticipation.next(null);
  }

  getCancelParticipationEvent():Observable<any> {
    console.log('[shared service] Get Cancel Participation event')
    return this.cancelParticipation.asObservable();
  }

  sendRefreshOfferViewEvent(){
    this.refreshOfferViewEvent.next(null);
  }

  getRefreshOfferViewEvent():Observable<any>{
    return this.refreshOfferViewEvent.asObservable();
  }

  sendToken(token: any) {
    console.log('[shared service] Send Token :', token);
    this.token.next(token);
  }

  getToken() {
    console.log('[shared service] Get Token');
    return this.token.asObservable();
  }

  setIdpHint(provider: any) {
    console.log('[shared service] provider :', provider);
    this.idpHint.next(provider);
  }

  getIdpHint(provider: any) {
    console.log('[shared service] provider :', provider);
    this.idpHint.asObservable();
  }
}
