import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  // public apiUrl = 'https://api-features-testing1.testing.commercantsdequartier.com';
  public apiUrl = "http://localhost:9191";
  public ionicDeployChannel = '';
  public automaticUpdateEnabled = true;
  public allowDenyUpdate = true;
  public frontCdq = "https://riverains.ouilovelocal.com";
  public frontCdqPro = "https://www.ouilovelocal.com";
  public keycloak = {
    // url: 'https://sso-testing1.testing.commercantsdequartier.com',
    url: 'http://localhost:8180',
    realm: 'cdq',
    clientId: 'front-app',
    redirectUri: 'cdqapp:/',
    redirectUriBrowser: 'http://localhost:8100'
  };

  public firebaseConfig = {
    apiKey: "AIzaSyBw3JzDseFf2kzqcnZtJxmQmUGzdN9OhNo",
    authDomain: "cdmr-demo.firebaseapp.com",
    projectId: "cdmr-demo",
    storageBucket: "cdmr-demo.appspot.com",
    messagingSenderId: "178442545708",
    appId: "1:178442545708:web:d3b8a7fb973c6f2d157f89",
    measurementId: "G-R7M6HM6YRJ"
  }
  public GA_TRACKING_ID = 'G-RQX4MBKQ6P';
  public sourceFontTitle = '/assets/fonts/title-font.ttf';

  public enableDebug = true;
  public production = false
  public stripePublicKey = "pk_test_51MEAIaHKAlKPUH91Btqy4kCnmVWHpABthIUKQjcu7sa2ZIxOaZ2Q6mthn85IsQV4icyzpeqCgigVVBIP7eEu2OEc00ZiUHjo4K"

  constructor() { }
}
