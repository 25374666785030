import { registerPlugin } from '@capacitor/core';

export interface CDQPlugin {
  firebaseInstallationId(): Promise<{ firebaseInstallationId: string }>;
}

//const CDQCAPPlugin = registerPlugin('CDQPlugin');
//const CDQPlugin = registerPlugin<CDQPlugin>('CDQPlugin');
const CDQCAPPlugin = registerPlugin<CDQPlugin>('CDQPlugin');

export { CDQCAPPlugin }
