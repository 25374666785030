import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { gitVersion } from 'src/environments/git-version';
import * as Sentry from "@sentry/angular";
import {BrowserTracing} from "@sentry/tracing";

Sentry.init(
    {
      dsn: window["__env"].sentryUrl, 
      release: 'cdq@' + gitVersion.branch,
      dist: gitVersion.commit,
      tracesSampleRate: 1.0,
      debug: true,
      enabled: true,
      ignoreErrors: [],
      environment: 'testing',
      integrations: [
        new BrowserTracing({
          tracingOrigins: ['localhost:9191', window['__env'].apiUrl],
        }),
      ]
    }
);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));



defineCustomElements(window);
