import {EnvService} from "../environment/env.service";
import {KeycloakIonicService} from "./keycloak-ionic.service";


export function initializer(envService: EnvService, keycloakIonicService: KeycloakIonicService) {
    return (): Promise<any> => {
        
        return keycloakIonicService.initAuth();
    };
}
