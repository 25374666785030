import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertService } from '../alert/alert.service';
import { ToastController } from '@ionic/angular';
import { SharedService } from '../shared/shared.service';
import {EnvService} from "../environment/env.service";
import {KeycloakIonicService} from "./keycloak-ionic.service";


@Injectable()
export class KeycloakInterceptorService implements HttpInterceptor{

  /**
   * Constructor
   * @param keycloakService {KeycloakService}
   */
  constructor(
    private keycloakService: KeycloakIonicService,
    private router : Router,
    private alertService : AlertService,
    private toastCtrl : ToastController,
    private sharedService : SharedService,
    private envService: EnvService
  ) {
  }


  /**
   * Interceptor for user token
   * @param request {HttpRequest}
   * @param next {HttpHandler}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Exclude interceptor for login request:
    // console.log('debug reg intercept search', request.url.search(environment.keycloak.url));
    if (request.url.search(this.envService.keycloak.url) === -1 && this.keycloakService.getKeycloakInstance().authenticated) {
      return this.getUserToken().pipe(
        mergeMap((token) => {
          if (token) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`
              }
            });
          }
          // return next.handle(request);
          return next.handle(request).pipe( tap(() => {},
          (err: any) => {
            if(err instanceof HttpErrorResponse) {
              if (err.status !== 401) {
                return;
              }
              this.alertService.success('Erreur', 'En raison d\'une période d\'inactivité, vous avez été automatiquement déconnecté.').then( async () => {
                this.keycloakService.logout()
                await this.router.navigateByUrl('/offline/home-web')
              }).catch(e => {
                console.log(e)
              })
            }
          }));
        }))
    } else {

      return next.handle(request).pipe( tap(() => {},
      (err:any) => {
        if(err instanceof HttpErrorResponse) {
          if(err.status !== 500) {
            return;
          }
          // if(this.router.url.includes('register')) {
          //     console.log('Error')
          //     this.sharedService.sendRegisterEvent()
          //     this.createToast()
          // }
        }
      }))
    }
  }

  /**
   * Return observable token
   */
  getUserToken() {
    const tokenPromise: Promise<string> = this.keycloakService.getToken();
    const tokenObservable: Observable<string> = from(tokenPromise);
    return tokenObservable;
  }

  async createToast() {
    const toast = await this.toastCtrl.create({
      position: "top",
      header: 'Erreur',
      message: 'Informations invalides',
      color: 'danger',
      duration: 3000,
      buttons: [
          {
              side: "end",
              text: "OK",
              role: "cancel",
              handler: () => {
                  console.log("Cancel clicked");
                  },
              },
          ],
      });
      toast.present();
  }


}
